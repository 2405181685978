import React, { useEffect, useState } from 'react';
import './badge.css';

const Badge = ({ value }) => {
  const [count, setCount] = useState(value);
  const [animationKey, setAnimationKey] = useState(0);

  useEffect(() => {
    setCount(value);
    setAnimationKey(prevKey => prevKey + 1);
  }, [value]);

  return (
    <span key={animationKey} className="badge">{count}</span>
  );
};

export default Badge;