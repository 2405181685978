import React, { useState, useEffect, useCallback, useRef, createContext, useContext, useMemo, lazy, Suspense } from "react";
import Navbar from "../../components/navbar/navbar.jsx";
// import axios from "axios";
import { useAppContext } from "../../contexts/AppContext.js";
import Footer from "../../components/footer/footer.jsx";
import { FaXmark, FaFilter, FaFilterCircleXmark, FaSistrix } from "react-icons/fa6";
// import { useToast } from "../../contexts/toastContext.js";
// import { getUrlApi } from "../../funcoes.js";
import ProdutoFiltro from "../../components/produto-filtro/produto-filtro.jsx";
import BarraFiltro from "../../components/barra-filtro/barra-filtro.jsx";

const ProdutoVitrine = lazy(() => import("../../components/produto-vitrine/produto-vitrine.jsx"));
const DadosFiltroContext = createContext();

function Home() {
  const filtroVazio = useMemo(() => {
    return { ordenacao: '', precoMinimo: '', precoMaximo: '', categoriasSelecionadas: [] };
  }, []);

  const { apptitulo, appmensagem, corfontedestaque, corfontenormal, urlbase, botaodestaquecor, produtos, setProdutos, produtosCopia } = useAppContext();
  // const [produtos, setProdutos] = useState([]);
  // const [setProdutosCopia] = useState([]);
  const [show, setShow] = useState(false);
  // const { showToast, updateToast } = useToast();
  const [dadosFiltro, setDadosFiltro] = useState(filtroVazio);
  const [filtrou, setFiltrou] = useState(false);
  const [inputPesquisa, setInputPesquisa] = useState('');
  const inputPesquisaRef = useRef(null);
  const [categoriaSelecionada, setCategoriaSelecionada] = useState(0);

  const html = document.querySelector('html');
  console.log('home cnpj ', urlbase);

  const filtroStyle = {
    color: botaodestaquecor
  }

  const pesquisaStyle = {
    color: corfontedestaque
  }

  const hideDock = useCallback(() => {
    html.style.overflow = 'auto';
    setShow(false);
  }, [html]);

  useEffect(() => {
    window.addEventListener('openSidebarFilter', function () {
      html.style.overflow = 'hidden';

      setShow(true);
    });

    return () => {
      window.removeEventListener('openSidebarFilter', null);
    };
  }, [html, hideDock]);

  useEffect(() => {
    // async function fetchProdutos() {
    //   showToast('Carregando produtos', {
    //     type: 'loading', theme: 'dark', position: 'top-center', isLoading: true, hideProgressBar: true
    //   });

    //   const authConfig = {
    //     auth: {
    //       username: 'Portal',
    //       password: 'Portal1163'
    //     }
    //   };

    //   try {
    //     const responseProd = await axios.get(`${getUrlApi(false)}/produtos/${urlbase}`, authConfig);
    //     const listaProduto = await responseProd.data.map(produto => ({ ...produto, categorias: [] }));

    //     console.log('lista produtos ', responseProd);
    //     updateToast('Carregando categorias');

    //     const responseCat = await axios.get(`${getUrlApi(false)}/categoriasproduto/${urlbase}`, authConfig);
    //     console.log('lista categoriasproduto ', responseCat.data);

    //     const produtosComCategorias = await listaProduto.map(produto => {
    //       const categoriasDoProduto = responseCat.data.filter(categoria => categoria.produto === produto.controle);

    //       produto.categorias = categoriasDoProduto;

    //       return produto;
    //     });

    //     console.log('lista produtos pronta ', produtosComCategorias);
    //     setProdutos(produtosComCategorias);
    //     setProdutosCopia(produtosComCategorias);

    //     setTimeout(() => {
    //       updateToast('Pronto', { type: "success", isLoading: false, autoClose: 1000, hideProgressBar: true });
    //     }, 100);
    //   } catch (error) {
    //     console.error("Erro ao obter produtos:", error);

    //     updateToast('Falha ao obter produtos', {
    //       type: 'error', isLoading: false, theme: 'colored', position: 'top-center', hideProgressBar: true, autoClose: 5000
    //     });
    //   }
    // }

    if (urlbase) {
      console.log('focoooooo');
      // fetchProdutos();
      inputPesquisaRef.current.focus();
    }
  }, [urlbase]);

  const openSidebarFilter = () => {
    const event = new CustomEvent('openSidebarFilter');
    window.dispatchEvent(event);
  }

  const aplicarFiltro = useCallback((busca = '', codCategoria = 0) => {
    console.log('busca ', busca, codCategoria, dadosFiltro);
    inputPesquisaRef.current.blur(); // remove o foco;

    const precoMinimo = dadosFiltro.precoMinimo.trim() === '' ? 0 : parseFloat(dadosFiltro.precoMinimo.replace(',', '.'));
    const precoMaximo = dadosFiltro.precoMaximo.trim() === '' ? 999999 : parseFloat(dadosFiltro.precoMaximo.replace(',', '.'));

    var produtosFiltrados = [...produtosCopia].sort((produto1, produto2) => {
      let campo1 = '';
      let campo2 = '';

      if ((dadosFiltro.ordenacao === 'C') || (dadosFiltro.ordenacao === 'D')) { // Crescrente/Decrescente;
        campo1 = produto1.nome.toLowerCase();
        campo2 = produto2.nome.toLowerCase();
      } else if ((dadosFiltro.ordenacao === 'MA') || (dadosFiltro.ordenacao === 'ME')) { // Maior Preço/Menor Preço;
        campo1 = produto1.valor;
        campo2 = produto2.valor;
      } else if ((dadosFiltro.ordenacao === 'MAV') || (dadosFiltro.ordenacao === 'MEV')) { // Mais Vendidos/Menos Vendidos;
        campo1 = produto1.vendas;
        campo2 = produto2.vendas;
      }

      if ((dadosFiltro.ordenacao === 'C') || (dadosFiltro.ordenacao === 'ME') || (dadosFiltro.ordenacao === 'MEV')) {
        if (campo1 < campo2) {
          return -1;
        }
        if (campo1 > campo2) {
          return 1;
        }
      } else {
        if (campo1 < campo2) {
          return 1;
        }
        if (campo1 > campo2) {
          return -1;
        }
      }

      if (dadosFiltro.ordenacao === 'MAV') {
        if (produto1.nome < produto2.nome) {
          return -1;
        } else if (produto1.nome > produto2.nome) {
          return 1;
        } else {
          return 0;
        }
      } else if (dadosFiltro.ordenacao === 'MEV') {
        if (produto1.nome < produto2.nome) {
          return 1;
        } else if (produto1.nome > produto2.nome) {
          return -1;
        } else {
          return 0;
        }
      }

      return 0;
    });

    // Filtro de valores;
    if ((precoMinimo >= 0) || (precoMaximo >= 0)) {
      produtosFiltrados = produtosFiltrados.filter(produto => {
        return produto.valor >= precoMinimo && produto.valor <= precoMaximo;
      });
    } else {
      produtosFiltrados = produtosFiltrados.filter(produto => {
        return produto.valor > 0;
      });
    }

    // Pesquisa;
    produtosFiltrados = produtosFiltrados.filter(produto => {
      return produto.nome.toLowerCase().includes(busca.toLowerCase());
    });

    // Categoria;
    if ((codCategoria > 0) && (dadosFiltro.categoriasSelecionadas.length === 0)) {
      produtosFiltrados = produtosFiltrados.filter(produto => {
        return produto.categorias.some(categoria => categoria.categoria === codCategoria);
      });
    }

    // Várias Categorias selecionadas;
    if (dadosFiltro.categoriasSelecionadas.length > 0) {
      produtosFiltrados = produtosFiltrados.filter(produto => {
        return produto.categorias.some(categoria => dadosFiltro.categoriasSelecionadas.includes(categoria.categoria));
      });
    }

    setProdutos(produtosFiltrados);
  }, [dadosFiltro, produtosCopia, setProdutos]);

  const filtrar = useCallback(() => {
    aplicarFiltro(inputPesquisa, categoriaSelecionada);

    if (JSON.stringify(dadosFiltro) !== JSON.stringify(filtroVazio)) {
      setFiltrou(true);
    } else {
      setFiltrou(false);
    }

    hideDock();
  }, [aplicarFiltro, inputPesquisa, categoriaSelecionada, dadosFiltro, setFiltrou, hideDock, filtroVazio]);

  useEffect(() => {
    filtrar();
  }, [dadosFiltro, filtrar]);

  const limparFiltros = () => {
    if (dadosFiltro.categoriasSelecionadas.length > 0)
      setCategoriaSelecionada(0);

    setDadosFiltro(filtroVazio);

    setFiltrou(false);
    // setProdutos(produtosCopia);
    // aplicarFiltro(inputPesquisa, categoriaSelecionada);
    hideDock();
    inputPesquisaRef.current.focus();
  }

  const handleInputChangePesquisa = (event) => {
    // const busca = event.target.value;
    // setInputPesquisa(busca);
    if (event.target.value === '')
      setInputPesquisa('');
    // if ((busca.trim() === '') && (produtos.length !== produtosCopia.length)) {
    // aplicarFiltro(busca, categoriaSelecionada);
    // }
  };

  const pesquisar = (event) => {
    console.log('event key type ', event.key, event.type);
    const busca = inputPesquisaRef.current.value;

    if ((event.key === 'Enter') || (event.type === 'click')) {
      setInputPesquisa(busca);
    }

    if ((event.key === 'Enter') || (event.type === 'click')) {
      aplicarFiltro(busca, categoriaSelecionada);
    } else if (event.key === 'Escape') {
      inputPesquisaRef.current.select();
    }
  }

  const limparPesquisa = () => {
    inputPesquisaRef.current.value = '';
    setInputPesquisa('');
    // if (produtos.length !== produtosCopia.length) {
    // aplicarFiltro('', categoriaSelecionada);
    // }
  }

  const filtrarCategoria = (cat, isDelayedClick, isDragging) => {
    if ((!isDelayedClick) && (!isDragging)) {
      setDadosFiltro({ ...dadosFiltro, categoriasSelecionadas: [] });
      setCategoriaSelecionada(cat.controle);
      aplicarFiltro(inputPesquisa, cat.controle);
    }
  }

  return (
    <>
      {/* <DadosFiltroContext.Provider value={{ dadosFiltro, setDadosFiltro }}> */}
      <ProdutoFiltro
        show={show}
        setShow={setShow}
        hideDock={hideDock}
        dadosFiltro={dadosFiltro}
        setDadosFiltro={setDadosFiltro}
        filtrar={filtrar}
        limparFiltros={limparFiltros}
      />

      <Navbar
        showMenu={true}
        showCategoria={true}
        filtrarCategoria={filtrarCategoria}
        categoriaSelecionada={categoriaSelecionada}
        dadosFiltro={dadosFiltro}
      />

      <div className="container">
        <div className="titulo text-center">

          <h1 style={{ color: corfontedestaque }}>{apptitulo}</h1>
          <p className="subtitulo" style={{ color: corfontenormal }} dangerouslySetInnerHTML={{ __html: appmensagem }}></p>
        </div>
      </div>

      <div className="input-pesquisa" style={{ flexDirection: 'column' }}>
        <span>
          <input type="text" id="pesquisa" placeholder="Pesquisar" onChange={handleInputChangePesquisa} onKeyDown={pesquisar} ref={inputPesquisaRef} />
          <FaSistrix className="icone-pesquisa" style={pesquisaStyle} onClick={pesquisar} />

          {inputPesquisa.length > 0 &&
            <FaXmark className="icone-limpa-pesquisa" onClick={limparPesquisa} />
          }

          {!filtrou ?
            <FaFilter className="icone-filtro" onClick={openSidebarFilter} /> : <FaFilterCircleXmark className="icone-filtro" style={filtroStyle} onClick={openSidebarFilter} />
          }
        </span>

        <BarraFiltro
          filtrou={filtrou}
          filtrar={filtrar}
          dadosFiltro={dadosFiltro}
          setDadosFiltro={setDadosFiltro}
        />
      </div>

      <div className="text-center lista-produtos">
        {produtos.length > 0 &&
          produtos.map(function (prod) {
            return (
              <Suspense key={prod.id}>
                <ProdutoVitrine
                  key={prod.id}
                  id={prod.id}
                  controle={prod.controle}
                  nome={prod.nome}
                  descricao={prod.descricao}
                  valor={prod.valor}
                  foto={prod.foto}
                  vendas={prod.vendas}
                />
              </Suspense>
            );
          })}
      </div>

      <Footer />
      {/* </DadosFiltroContext.Provider> */}
    </>
  );
}

export default Home;

export const useHomeContext = () => {
  const context = useContext(DadosFiltroContext);

  if (!context) {
    throw new Error('useAppContext deve ser usado dentro de um AppProvider');
  }

  return context;
};