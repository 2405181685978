import React from 'react';
import ReactDOM from 'react-dom/client';
import "./style/global.css";
import Rotas from "./rotas.js";
import { CartProvider } from "./contexts/cart-context.jsx";
import { AppProvider, useAppContext } from './contexts/AppContext.js';
import { ToastProvider } from './contexts/toastContext.js';

function AppWrapper() {
  const { corfundo } = useAppContext();

  return (
    <div className='fundo' style={{ backgroundColor: corfundo }}>
      <Rotas />
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
const urlParams = new URLSearchParams(window.location.search);
console.log('url ', urlParams);
// const cnpjParam = urlParams.get('cnpj') || "07157238000174"; // Use um valor padrão se não houver parâmetro
const cnpjParam = urlParams.get('cnpj') || "24423317000107";

root.render(
  <ToastProvider>
    <AppProvider cnpjParam={cnpjParam}>
      <CartProvider>
        <AppWrapper />
      </CartProvider>
    </AppProvider>
  </ToastProvider>
);
