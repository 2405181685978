import React, { createContext, useContext, useRef } from 'react';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastContext = createContext();

export const useToast = () => {
    return useContext(ToastContext);
};

export const ToastProvider = ({ children }) => {
    const toastRef = useRef(null);

    const showToast = (message, options = {}) => {
        const randomNumber = Math.floor(Math.random() * 1000000);
        const aOptions = { ...options, transition: Slide, toastId: randomNumber };
        return toastRef.current = toast(message, aOptions);
    };

    const updateToast = (message, options = {}) => {
        const aOptions = { ...options, transition: Slide };
        if (toastRef.current) {
            toast.update(toastRef.current, { render: message, ...aOptions });
        }
    };

    const dismissToast = (refToast = null) => {
        if (refToast)
            toast.dismiss(refToast)
        else
            toast.dismiss();
    }

    return (
        <ToastContext.Provider value={{ showToast, updateToast, dismissToast }}>
            <ToastContainer limit={2} />
            {children}
        </ToastContext.Provider>
    );
};