import { createContext, useEffect, useState } from "react";
import { useLocalStorage } from "../funcoes";
import { useToast } from "./toastContext.js";
import isEqual from 'lodash/isEqual';

const CartContext = createContext();

function CartProvider(props) {
    const [cartItems, setCartItems] = useLocalStorage('sacola', []);
    const [totalCart, setTotalCart] = useState(0);
    const { showToast } = useToast();

    /*
    item:
    {
        id: 1,
        nome: "X-Tudo",
        preco: 20.00,
        foto: "https://jornadajs-food.s3.amazonaws.com/spicy.png"
    }
    */
    useEffect(() => {
        CalcTotal(cartItems);
    }, [cartItems]);

    const mesmoItem = (arr1, arr2) => {
        if (arr2.length === 0) {
            return true;
        }

        if (arr1.length !== arr2.length) {
            return false;
        }

        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i].controle !== arr2[i].controle) {
                return false;
            }
        }
        console.log('é o mesmo item', true);
        return true;
    }

    const AddItemCart = (item, digitou) => {
        console.log('item cart ', item);
        let cartItemsNovo = [];
        let findItem = false;

        // Verificar se o item ja existe na sacola...
        for (var prod of cartItems) {
            // Encontrou
            if (prod.id === item.id) {
                // Verifica quando tem composição ou adicional;
                if ((item.composicao.length > 0) || (item.adicionais.length > 0)) {
                    console.log('COM composicao ou adicionais ', item);
                    if (mesmoItem(prod.composicao, item.composicao) && mesmoItem(prod.adicionais, item.adicionais)) {
                        if (!digitou)
                            item.qtd = prod.qtd + 1;

                        findItem = true;
                        cartItemsNovo.push(item);
                    } else {
                        cartItemsNovo.push(prod);
                    }
                } else {
                    // Quando tem composição e adicional mas não alterou nada mas já tem o mesmo Item na sacola;
                    if ((prod.composicao.length > 0) || (prod.adicionais.length > 0)) {
                        cartItemsNovo.push(prod)
                    } else {
                        console.log('SEM composicao ou adicionais ', item);
                        if (!digitou)
                            item.qtd = prod.qtd + 1;

                        findItem = true;
                        cartItemsNovo.push(item);
                    }
                }
            } else {
                console.log('novo ', prod);
                cartItemsNovo.push(prod);
            }
        }

        // Se nao encontrou o item ou é o primeiro item a ser inserido...
        if ((findItem === false) || (cartItems.length === 0)) {
            cartItemsNovo.unshift(item);
        }

        // Insere dados na lista do sacola...
        setCartItems(cartItemsNovo);
    }

    const RemoveItemCart = (item) => {
        let cartItemsNovo = [];

        // Localiza o item e atualiza...
        for (var prod of cartItems) {
            console.log('remove item ', prod, item);
            // Encontrou
            if (isEqual(prod, item)) {
                prod.qtd = prod.qtd - 1;
            }
            // if (prod.id === item.id) {
            //     prod.qtd = prod.qtd - 1;
            // }
            cartItemsNovo.push(prod);
        }

        // Remove itens zerados...
        cartItemsNovo = cartItemsNovo.filter(function (item) {
            if (item.qtd === 0) {
                showToast(item.nome + ' removido da sacola', {
                    type: 'info', theme: 'dark', position: 'bottom-center', hideProgressBar: true, autoClose: 1000
                });
            }
            return item.qtd > 0
        });

        // Atualiza lista do sacola...
        setCartItems(cartItemsNovo);
    }

    const LimpaItensCart = () => {
        setCartItems([]);
    }

    const CalcTotal = (items) => {
        let tot = 0;

        for (var item of items)
            tot = tot + (item.valor * item.qtd);

        setTotalCart(tot);
    }

    return (
        <>
            <CartContext.Provider value={{
                cartItems, setCartItems, AddItemCart,
                RemoveItemCart, totalCart, LimpaItensCart
            }}>
                {props.children}
            </CartContext.Provider>
        </>
    )
}

export { CartContext, CartProvider };