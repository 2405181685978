import "./produto-cart.css";
import semImagemDoItem from "../../assets/sem-imagem.png"
import { CartContext } from "../../contexts/cart-context";
import { useContext, useRef, useState } from "react";
import { FaPlus, FaMinus, FaRegTrashCan } from "react-icons/fa6";
import ModalDialog from "../modal-dialog/modal-dialog";

function ProdutoCart(props) {

    const { AddItemCart, RemoveItemCart } = useContext(CartContext);
    const [showDialog, setShowDialog] = useState(false);
    const handleModalResponse = useRef(null);
    const [quantidade, setQuantidade] = useState(props.qtd);

    const abrirDialog = () => {
        setShowDialog(true); // Abre o dialog;
        return new Promise((resolve, reject) => {
            return handleModalResponse.current = resolve;
        });
    };

    const AddItem = (qtde, digitou = true) => {
        const auxQtde = digitou ? qtde : qtde = quantidade + 1;

        const item = {
            id: props.id,
            controle: props.id,
            nome: props.nome,
            valor: props.valor,
            foto: props.foto,
            qtd: auxQtde,
            composicao: props.composicao,
            adicionais: props.adicionais,
            adicionaisComposicao: props.adicionaisComposicao,
            valoradicional: props.valoradicional,
            imagemCarregada: props.imagemCarregada
        }

        console.log('item sacola ', item);
        AddItemCart(item, digitou);
    }

    const RemoveItem = async () => {
        if (props.qtd === 1) {
            const response = await abrirDialog();
            if (response === 'cancelado') {
                return;
            }
        }

        const item = {
            id: props.id,
            controle: props.controle,
            nome: props.nome,
            valor: props.valor,
            foto: props.foto,
            qtd: props.qtd,
            composicao: props.composicao,
            adicionais: props.adicionais,
            adicionaisComposicao: props.adicionaisComposicao,
            valoradicional: props.valoradicional,
            imagemCarregada: props.imagemCarregada
        }

        RemoveItemCart(item);
    }

    const handleInputChange = (event) => {
        const { value } = event.target;

        if (value !== '') {
            if (parseInt(value) === 0) {
                setQuantidade(1);
            } else {
                setQuantidade(value);
            }
        } else
            setQuantidade(value);
    };

    const handleInputKeyDown = (event) => {
        if (event.key === 'Enter') {
            const { value } = event.target;

            if (value !== '') {
                if (parseInt(value) === 0) {
                    AddItem(1);
                } else {
                    AddItem(parseInt(value));
                }
            }
        }
    }

    return (
        <>
            <ModalDialog
                tipo={1}
                titulo={'Deseja remover o item ?'}
                subTitulo={''}
                confirmar={'Sim'}
                cancelar={'Não'}
                showDialog={showDialog}
                setShowDialog={setShowDialog}
                handleModalResponse={handleModalResponse.current}
                hideScrollAfterClose={true}
            />

            <div className="produto-cart-box">
                {/* <div className="produto-cart-foto" style={{ backgroundImage: `url(${props.imagemCarregada ? props.foto : semImagemDoItem})` }}> */}
                <img className="produto-cart-foto" loading="lazy" src={props.imagemCarregada ? props.foto : semImagemDoItem} alt="Foto" />
                {/* </div> */}

                <div className="produtos-cart-dados">
                    <div className="produtos-cart-nome-valor">
                        <p className="produto-cart-nome">{props.nome}</p>
                        <p className="produto-cart-valor">
                            {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(props.valor)}
                        </p>
                        <p className="composicao-adicionais">{props.adicionaisComposicao}</p>
                    </div>

                    <div className="footer-produto-cart">
                        <p className="footer-produto-preco text-left">
                            {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(props.valor * props.qtd)}
                        </p>

                        <div className="footer-add-rm">
                            <button onClick={RemoveItem} className="footer-produto-btn">{props.qtd <= 1 ? <FaRegTrashCan /> : <FaMinus />}</button>
                            <input type="number" className='footer-produto-qtd' value={quantidade} onChange={handleInputChange} onKeyDown={handleInputKeyDown} />
                            <button onClick={() => AddItem(1, false)} className="footer-produto-btn"><FaPlus /></button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProdutoCart;