import "./modal-dialog.css";
import React from 'react';
import Modal from "../modal/modal";
import { useAppContext } from "../../contexts/AppContext";
import { FaCircleQuestion } from "react-icons/fa6";

function ModalDialog(props) {

    const { botaodestaquecor, botaodestaquefonte, botaonormalcor, botaonormalfonte } = useAppContext();

    const confirmarStyle = {
        backgroundColor: botaodestaquecor,
        color: botaodestaquefonte,
    }

    const cancelarStyle = {
        backgroundColor: botaonormalcor,
        color: botaonormalfonte,
    }

    const cancelar = () => {
        props.handleModalResponse('cancelado');
        props.setShowDialog(false);
    };

    const confirmar = () => {
        props.handleModalResponse('confirmar');
        props.setShowDialog(false);
    };

    return (
        <Modal isOpen={props.showDialog} hideScrollAfterClose={props.hideScrollAfterClose}>
            <div className="modal-dialog">
                <div className="dialog-titulo">
                    {props.tipo === 1 && <FaCircleQuestion className="icone-dialog" />}

                    <span className="titulo">{props.titulo}</span>
                    <span className="subtitulo">{props.subTitulo}</span>
                </div>

                <div className="dialog-botao">
                    {props.tipo === 1 && <button className="btn btn-dark btn-cancelar" style={cancelarStyle} onClick={cancelar}>{props.cancelar}</button>}
                    {props.tipo === 1 && <button className="btn btn-red btn-confirmar" style={confirmarStyle} onClick={confirmar}>{props.confirmar}</button>}
                </div>
            </div>
        </Modal>
    );
}

export default ModalDialog;

