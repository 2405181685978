import React, { useContext, useEffect, useRef, useState } from 'react';
import "./navbar.css";
import Cart from "../cart/cart";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "../../contexts/AppContext.js";
import { FaBagShopping, FaArrowLeftLong } from "react-icons/fa6";
import compsoft from "../../assets/compsoft.webp";
import { CartContext } from '../../contexts/cart-context.jsx';
import Badge from '../badge/badge.jsx';
// import { categorias } from "../../dados.js";
import { useToast } from '../../contexts/toastContext.js';
// import axios from 'axios';
import { GetWidthScreen, capitalizeFirstLetter, useDragScroll } from '../../funcoes.js';

function Navbar(props) {
    const location = useLocation();
    const { botaodestaquecor, botaodestaquefonte, corfontedestaque, urlbase, categoria } = useAppContext();
    const [urlLogo, setUrlLogo] = useState('');
    const { cartItems } = useContext(CartContext);
    const navigate = useNavigate();
    const divRef = useRef(null);
    const { handleMouseDown, handleMouseMove, handleTouchStart, handleTouchMove, isDragging, isDelayedClick } = useDragScroll();
    // const [categoria, setCategoria] = useState([]);
    const { showToast } = useToast();
    const screenSize = GetWidthScreen();

    useEffect(() => {
        // async function fetchCategorias() {
        //     try {
        //         const response = await axios.get(`${getUrlApi(false)}/categorias/${urlbase}`, {
        //             auth: {
        //                 username: 'Portal',
        //                 password: 'Portal1163'
        //             }
        //         });

        //         console.log('lista categorias ', response.data);
        //         const listaCategoria = [{ controle: 0, nome: 'Todos' }, ...response.data]
        //         setCategoria(listaCategoria);
        //     } catch (error) {
        //         console.error("Erro ao obter categorias:", error);

        //         showToast('Falha ao obter as Categorias', {
        //             type: 'error', theme: 'colored', position: 'top-center', hideProgressBar: true, autoClose: 5000
        //         });
        //     }
        // }

        // if (urlbase) {
        //     fetchCategorias();
        // }
    }, [urlbase, showToast]);

    useEffect(() => {
        if (urlbase)
            setUrlLogo(`https://compsoft.com.br/cscardapio/imagens/${urlbase}/logo.png`);

        const currentPath = window.location.pathname;
        const container = document.getElementsByClassName('container');

        if (currentPath !== '/') {
            if (screenSize > 600)
                container[0].style.marginTop = '100px';
            else
                container[0].style.marginTop = '70px';
        }
    }, [urlbase, screenSize]);

    const openSidebar = () => {
        const event = new CustomEvent('openSidebar');
        window.dispatchEvent(event);
    }

    const sacolaStyle = {
        backgroundColor: botaodestaquecor,
        color: botaodestaquefonte,
    };

    const linkCardapioStyle = {
        color: location.pathname === "/" ? corfontedestaque : "#3c3c3c"
    }

    const linkHistoricoStyle = {
        color: location.pathname === "/historico" ? corfontedestaque : "#3c3c3c"
    }

    const categoriaStyle = {
        color: corfontedestaque,
        position: 'absolute',
        left: '0',
        bottom: '0',
        width: '100%',
        height: '3px',
        backgroundColor: `${corfontedestaque}`
    }

    const navBarStyle = {
        height: screenSize > 600 ? '90px' : '70px'
    }

    return (
        <div id="navbar" className="navbar" style={(!props.showCategoria) ? navBarStyle : (
            categoria.length === 0 ? (screenSize > 600 ? { height: '90px' } : { height: '70px' }) : {}
        )}>

            <div className='navbar-topo'>
                {props.showMenu &&
                    <div className="logotipo">
                        <Link to="/">
                            <img src={urlLogo} alt="Logotipo" loading='lazy' onError={() => { setUrlLogo(compsoft) }} />
                        </Link>
                    </div>
                }

                {props.showMenu &&
                    <div className="menu">
                        <Link to="/" className='link text-red' style={linkCardapioStyle}>
                            Cardápio
                        </Link>

                        <Link to="/historico" className='link text-red' style={linkHistoricoStyle}>
                            Histórico
                        </Link>

                        <button onClick={openSidebar} className="btn btn-red btn-sacola" style={sacolaStyle}>
                            <FaBagShopping /> Sacola {cartItems.length > 0 && <Badge value={cartItems.length} />}
                        </button>
                    </div>
                }

                {props.showBack &&
                    <div className="menu" style={{ justifyContent: 'flex-start', marginLeft: '20px' }}>
                        <FaArrowLeftLong className='link back-button' style={{ fontSize: '1.6rem' }} onClick={() => navigate(-1)} />
                    </div>
                }

                {props.titulo &&
                    <div className="titulo text-center" style={{ width: '100%', textAlign: 'left', marginLeft: '20px' }}>
                        <h1 style={{ fontSize: '1.4rem' }}>{props.titulo}</h1>
                    </div>
                }
            </div>

            {props.showCategoria && categoria.length > 0 &&
                <div className="filtro-categoria"
                    ref={divRef}
                    onMouseDown={(e) => handleMouseDown(e, divRef)}
                    onMouseMove={(e) => handleMouseMove(e, divRef)}
                    onTouchStart={(e) => handleTouchStart(e, divRef)}
                    onTouchMove={(e) => handleTouchMove(e, divRef)}
                >
                    {categoria.map(function (cat, index) {
                        return (
                            <div key={index} className="btn-wrapper">
                                <button className="btn btn-categoria" onClick={() => { props.filtrarCategoria(cat, isDelayedClick, isDragging) }}>
                                    <span>{capitalizeFirstLetter(cat.nome)}</span>
                                </button>

                                <div style={
                                    (((props.categoriaSelecionada === cat.controle) && (props.dadosFiltro.categoriasSelecionadas.length === 0))
                                        || (props.dadosFiltro.categoriasSelecionadas[props.dadosFiltro.categoriasSelecionadas.indexOf(cat.controle)] === cat.controle)
                                    )
                                        ? categoriaStyle : {}}
                                ></div>
                            </div>
                        );
                    })}
                </div>
            }

            <Cart />
        </div>
    );
}

export default Navbar;
