import "./produto-filtro.css";
import React, { useEffect, useState } from 'react';
import { Dock } from "react-dock";
import { FaArrowDownWideShort, FaXmark, FaBrazilianRealSign, FaList } from "react-icons/fa6";
import { capitalizeFirstLetter, useDockWidth } from '../../funcoes';
import { useToast } from "../../contexts/toastContext";
import { useAppContext } from "../../contexts/AppContext";

function ProdutoFiltro({ show, setShow, hideDock, dadosFiltro, setDadosFiltro, limparFiltros }) {
    const dockSize = useDockWidth();
    const html = document.querySelector('html');
    const filtroVazio = { ordenacao: '', precoMinimo: '', precoMaximo: '', categoriasSelecionadas: [] };
    const [filtro, setFiltro] = useState(filtroVazio);
    // const { dadosFiltro, setDadosFiltro } = useHomeContext();
    const { showToast } = useToast();
    const { categoria, botaodestaquecor, botaodestaquefonte } = useAppContext();

    const categoriaStyle = (cat) => {
        return {
            color: filtro.categoriasSelecionadas.includes(cat.controle) ? botaodestaquefonte : '#101010',
            backgroundColor: filtro.categoriasSelecionadas.includes(cat.controle) ? botaodestaquecor : '#ffffff'
        }
    }

    useEffect(() => {
        setFiltro(dadosFiltro);
    }, [dadosFiltro, setFiltro]);

    const handleInputChange = (event) => {
        const { id, value } = event.target;

        setFiltro({ ...filtro, [id]: value });
    };

    const toggleCategoriaSelecionada = (categoriaControle) => {
        const index = filtro.categoriasSelecionadas.indexOf(categoriaControle);
        if (index !== -1) {
            const nova = filtro.categoriasSelecionadas.filter((controle) => controle !== categoriaControle);
            setFiltro({ ...filtro, categoriasSelecionadas: nova });
        } else {
            const nova = [...filtro.categoriasSelecionadas, categoriaControle];
            setFiltro({ ...filtro, categoriasSelecionadas: nova });
        }
    }

    const aplicar = () => {
        if ((JSON.stringify(filtro) === JSON.stringify(filtroVazio)) && (JSON.stringify(filtro) === JSON.stringify(dadosFiltro))) {
            showToast('Nenhum filtro foi aplicado', {
                type: 'warning', theme: 'dark', position: 'top-center', hideProgressBar: true, autoClose: 1000
            });
        }

        setDadosFiltro(filtro);
    }

    const limpar = () => {
        setFiltro({ ordenacao: '', precoMinimo: '', precoMaximo: '', categoriasSelecionadas: [] });
        limparFiltros();
    }

    // const handleInputChangeNumeric = (event) => {
    //     const { id, value } = event.target;
    //     if (/^\d*([,.]?\d{0,2})?$/.test(value)) {
    //         dadosFiltro.current = {
    //             ...dadosFiltro.current,
    //             [id]: value
    //         };
    //     }
    // };

    return (
        <Dock
            position="right"
            isVisible={show}
            fluid={false}
            size={dockSize}
            onVisibleChange={(visible) => {
                setFiltro(dadosFiltro);
                html.style.overflow = 'auto';
                setShow(visible);
            }}
            dockStyle={{ borderRadius: '10px' }}
        >
            <div className={`fundo-filtro ${show ? 'dock-open' : ''}`}>
                <div className="text-center meu-filtro">
                    <h1 className="meu-filtro-h1">Filtros</h1>
                    <FaXmark className="btn-fechar" onClick={() => {
                        setFiltro(dadosFiltro);
                        hideDock();
                    }} />
                </div>

                <div className="lista-filtro">
                    <div style={{ margin: '20px' }}>
                        <div className="input-group">
                            <label htmlFor="ordenacao" className="ordenacao"><FaArrowDownWideShort className="icone-group" />Organizar produtos em ordem</label>
                            {/* <p><FaArrowDownWideShort className="icone-group" />Organizar produtos em ordem</p> */}
                            <select id="ordenacao" className="ordenacao" value={filtro.ordenacao} onChange={handleInputChange}>
                                <option value="">Ordenação padrão</option>
                                <option value="C">Cresente (A-Z)</option>
                                <option value="D">Decrescente (Z-A)</option>
                                <option value="MA">Maior preço</option>
                                <option value="ME">Menor preço</option>
                                <option value="MAV">Mais vendidos</option>
                                <option value="MEV">Menos vendidos</option>
                            </select>
                        </div>

                        <div className="input-group">
                            <p><FaBrazilianRealSign className="icone-group" />Preço</p>
                            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                <input type="number" id="precoMinimo" placeholder="Mínimo" value={filtro.precoMinimo} onChange={handleInputChange} />
                                <span>-</span>
                                <input type="number" id="precoMaximo" placeholder="Máximo" value={filtro.precoMaximo} onChange={handleInputChange} />
                            </div>
                        </div>

                        <div className="input-group">
                            <p><FaList className="icone-group" />Categorias</p>
                            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                                {categoria.map((cat, index) => (
                                    cat.controle > 0 &&
                                    <div key={index} style={categoriaStyle(cat)} className="btn filtro-categorias">
                                        <span onClick={() => {
                                            toggleCategoriaSelecionada(cat.controle);
                                        }}
                                        >{capitalizeFirstLetter(cat.nome)}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-filtro">
                    <div className="filtro">
                        <button className="btn btn-filtro" onClick={aplicar}>
                            Aplicar
                        </button>
                    </div>

                    <div className="filtro">
                        <div className="btn-limpar-filtro">
                            <span onClick={limpar} className={`text-red limpar`}>
                                Limpar filtros
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </Dock >
    );
}

export default ProdutoFiltro;