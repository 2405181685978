import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from "axios";

export function getUrlApi(aTeste) {
    if (!aTeste) {
        return 'http://localhost:10002'
    } else
        return 'https://v1-csapi.compsoft.com.br:10001'
};

export function getAuth() {
    return {
        username: 'Portal',
        password: 'Portal1163'
    };
};

export async function InfoError(cnpj, classe, mensagem) {
    let data = `[{"CNPJ": "' ${cnpj} '", "PDV": "0", "CLASSE": "' ${classe} '", "MENSAGEM": "' ${mensagem} '"}]`;
    const auth = getAuth();

    const response = await axios.post('http://csapi.compsoft.com.br:9008/error', data, {
        auth: auth
    });

    return response;
};

export function capitalizeFirstLetter(string) {
    if (string)
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    else
        return '';
};

export function GetWidthScreen() {
    const [screenSize, setScreenSize] = useState(1278);

    useEffect(() => {
        const calculateScreenSize = () => {
            const windowWidth = window.innerWidth;
            setScreenSize(windowWidth);
        }

        calculateScreenSize();

        const handleResize = () => {
            calculateScreenSize();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return screenSize;
};

export function useLocalStorage(key, initialValue) {
    const [value, setValue] = useState(() => {
        try {
            const localValue = window.localStorage.getItem(key);
            console.log('item storage ', localValue);
            return localValue ? JSON.parse(localValue) : initialValue;
        } catch (error) {
            console.log('erro storage ', error);
            return initialValue;
        }
    });

    useEffect(() => {
        window.localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
};

export function useDockWidth() {
    const [dockSize, setDockSize] = useState(420);

    useEffect(() => {
        const calculateDockSize = () => {
            const windowWidth = window.innerWidth;
            let calculatedSize = Math.max(200, Math.floor((windowWidth / 5)) * 5);
            calculatedSize = Math.min(calculatedSize, 420);
            setDockSize(calculatedSize);
        };

        calculateDockSize();

        const handleResize = () => {
            calculateDockSize();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return dockSize;
};

export default function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'instant' });
    }, [pathname]);

    return null;
};

export function formatComposicaoAdicionais(item) {
    var composicaoAdicionais = '';

    if (item.composicao) {
        for (var i = 0; i < item.composicao.length; i++) {
            composicaoAdicionais = composicaoAdicionais + 'SEM ' + item.composicao[i].descricao + '/ ';
        }
    }

    if (item.adicionais) {
        for (i = 0; i < item.adicionais.length; i++) {
            composicaoAdicionais = composicaoAdicionais + 'COM ' + item.adicionais[i].descricao + '/ ';
        }
    }
    return composicaoAdicionais;
};

export const useDragScroll = () => {
    const [startX, setStartX] = useState(null);
    const [scrollLeft, setScrollLeft] = useState(null);
    const [isDelayedClick, setIsDelayedClick] = useState(false);
    const [isDragging, setIsDragging] = useState(false);

    const handleMouseDown = (e, divRef) => {
        e.preventDefault();
        setStartX(e.pageX - divRef.current.offsetLeft);
        setScrollLeft(divRef.current.scrollLeft);
        setIsDragging(true);
        setIsDelayedClick(false);

        setTimeout(() => {
            setIsDelayedClick(true);
        }, 200);
    };

    const handleMouseMove = (e, divRef) => {
        if (!isDragging) return;
        const x = e.pageX - divRef.current.offsetLeft;
        const walk = (x - startX) * 1; // Ajuste o multiplicador conforme necessário para ajustar a velocidade de rolagem
        divRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleTouchStart = (e, divRef) => {
        const firstTouch = e.touches[0];
        setStartX(firstTouch.clientX);
        setScrollLeft(divRef.current.scrollLeft);
        setIsDragging(true);
    };

    const handleTouchMove = (e, divRef) => {
        if (!isDragging) return;
        const firstTouch = e.touches[0];
        const x = firstTouch.clientX;
        const walk = (x - startX) * 1; // Ajuste o multiplicador conforme necessário para ajustar a velocidade de rolagem
        divRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleTouchEnd = () => {
        setIsDragging(false);
    };

    useEffect(() => {
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('touchend', handleTouchEnd);

        return () => {
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('touchend', handleTouchEnd);
        };
    }, []);

    return { handleMouseDown, handleMouseMove, handleTouchStart, handleTouchMove, isDragging, isDelayedClick };
};

export function convertHexToRgba(hex, alpha) {
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};