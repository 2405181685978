import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home/home.jsx";
import Checkout from "./pages/checkout/checkout.jsx";
import Historico from "./pages/historico/historico.jsx";
import { useEffect, useState } from "react";
import { FaAngleUp } from "react-icons/fa6";
import { useAppContext } from "./contexts/AppContext.js";
import ScrollToTop, { GetWidthScreen } from "./funcoes.js";

function Rotas() {

  const [isVisible, setIsVisible] = useState(false);
  const { botaodestaquecor, botaodestaquefonte } = useAppContext();
  const screenSize = GetWidthScreen();

  const topoStyle = {
    backgroundColor: botaodestaquecor,
    color: botaodestaquefonte,
    pointerEvents: 'auto'
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const header = document.getElementById('navbar');
      const headerClassList = header.classList;
      const currentPath = window.location.pathname;

      if (window.scrollY >= 145) {
        if (!headerClassList.contains('scrollSize')) {
          headerClassList.add('scrollSize');
        }

        if (currentPath !== '/') {
          if (screenSize > 600)
            header.style.height = '70px'
          else
            header.style.height = '50px'
        }
        setIsVisible(true);
      } else {
        if (headerClassList.contains('scrollSize')) {
          headerClassList.remove('scrollSize');

          if (currentPath !== '/')
            header.style.height = 'inherit';
        }
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [screenSize]);

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/historico" element={<Historico />} />
        </Routes>

        {isVisible && (
          <div className="fundo-scroll-to-top">
            <div id='scroll-to-top' className={`scroll-to-top-button-wrapper ${isVisible ? 'visible' : ''}`}>

              <button onClick={scrollToTop} className="btn btn-red scroll-to-top-button" style={topoStyle}>
                <FaAngleUp />
              </button>
            </div>
          </div>
        )}
      </BrowserRouter>
    </>
  );
}

export default Rotas;
