import React, { useEffect } from 'react';
import "./modal.css";

function Modal({ isOpen, children, hideScrollAfterClose = false }) {

    useEffect(function () {
        const html = document.querySelector('html');
        const scrollToTop = document.querySelector('#scroll-to-top');

        if (isOpen) {
            html.style.overflow = 'hidden';

            if (scrollToTop)
                scrollToTop.style.display = 'none';
        } else {
            if (!hideScrollAfterClose) {
                html.style.overflow = 'auto';

                if (scrollToTop)
                    scrollToTop.style.display = '';
            }
        }
    }, [isOpen, hideScrollAfterClose]);

    if (isOpen) {
        return (
            <div className='fundo-modal'>
                <div className='modal'>
                    {children}
                </div>
            </div>
        );
    }
}

export default Modal;