import Navbar from "../../components/navbar/navbar.jsx";
import "./checkout.css";
import { CartContext } from "../../contexts/cart-context.jsx";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAppContext } from "../../contexts/AppContext.js";
import { FaMotorcycle, FaPersonWalking, FaSistrix } from "react-icons/fa6";
import { useToast } from "../../contexts/toastContext.js";
import { InfoError, getAuth, getUrlApi } from "../../funcoes.js";
import ModalDialog from "../../components/modal-dialog/modal-dialog.jsx";

function Checkout() {

    const { urlbase, setCarregarProdutos, corfontedestaque } = useAppContext();
    const { cartItems, totalCart, LimpaItensCart } = useContext(CartContext);
    const [dadosPessoais, setDadosPessoais] = useState({ nome: '', email: '', fone: '', cep: '', endereco: '', numero: '', bairro: '', cidade: '', uf: 'SP', obs: '' });
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState('entrega');
    const [taxaEntrega, setTaxaEntrega] = useState(0);
    const { showToast, updateToast } = useToast();
    const [finalizou, setFinalizou] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [erros, setErros] = useState({});
    const inputNomeRef = useRef(null);
    const inputFoneRef = useRef(null);
    const inputNumeroRef = useRef(null);
    const auth = getAuth();
    const handleModalResponse = useRef(null);

    const pesquisaStyle = {
        color: corfontedestaque,
        right: '0px'
    }

    useEffect(() => {
        setTaxaEntrega(selectedOption === 'entrega' ? 0 : 0);
    }, [selectedOption]);

    useEffect(() => {
        inputNomeRef.current.focus();
    }, []);

    const abrirDialog = () => {
        setShowDialog(true); // Abre o dialog;
        return new Promise((resolve, reject) => {
            return handleModalResponse.current = resolve;
        });
    };

    const finalizar = async () => {
        console.log('item ', cartItems);

        const novosErros = {};
        if (!dadosPessoais.nome) {
            novosErros.nome = ' *** Campo Nome é obrigatório';
        }
        if (!dadosPessoais.fone) {
            novosErros.fone = ' *** Campo Telefone é obrigatório';
        }

        setErros(novosErros);
        if (Object.keys(novosErros).length > 0) {
            showToast('Preencha os campos obrigatórios', {
                type: 'warning', theme: 'dark', position: 'bottom-center', hideProgressBar: true, autoClose: 3000
            });

            if (novosErros.nome) {
                inputNomeRef.current.focus();
            } else if (novosErros.fone) {
                inputFoneRef.current.focus();
            }

            return;
        }

        setFinalizou(true);
        const response = await abrirDialog();
        if (response === 'cancelado') {
            setFinalizou(false);
            return;
        }

        console.log('finalizaaaaaaaa ', response);

        showToast('Enviando pedido', {
            type: 'loading', theme: 'dark', position: 'bottom-center', isLoading: true, hideProgressBar: true
        });

        try {
            const data = {
                nome: dadosPessoais.nome,
                email: dadosPessoais.email,
                telefone: dadosPessoais.fone,
                cep: dadosPessoais.cep,
                endereco: dadosPessoais.endereco,
                numero: dadosPessoais.numero,
                bairro: dadosPessoais.bairro,
                cidade: dadosPessoais.cidade,
                uf: dadosPessoais.uf,
                valortotal: totalCart,
                retira: selectedOption === 'entrega' ? 'N' : 'S',
                valorentrega: taxaEntrega,
                qtditens: cartItems.length,
                observacao: dadosPessoais.obs,
                itens: cartItems.map((item, index) => {
                    return {
                        item: index + 1,
                        produto: item.controle,
                        descricao: item.nome,
                        unitario: item.valor,
                        quantidade: item.qtd,
                        total: item.qtd * item.valor,
                        // adicionais: item.adicionais,
                        // composicao: item.composicao
                        composicao: item.adicionaisComposicao,
                        valoradicional: item.valoradicional,
                        inteiro: 'S',
                        tamanho: 0
                    }
                })
            };

            console.log('finalizou ', data);
            const response = await axios.post(`${getUrlApi(false)}/pedido/${urlbase}`, data, {
                auth: auth
            });

            console.log('post ', response.data);

            setTimeout(() => {
                updateToast('Pedido enviado com sucesso', { type: "success", theme: 'colored', isLoading: false, autoClose: 3000, hideProgressBar: true });
                LimpaItensCart();
                navigate('/', { replace: true });
                setCarregarProdutos(true);
            }, 500);
        } catch (error) {
            // navigate('/');
            setFinalizou(false);
            updateToast('Falha ao enviar o pedido', { type: "error", theme: 'colored', isLoading: false, autoClose: 3000, hideProgressBar: true });
            console.error("Erro ao finalizar o pedido:", error);

            let res = await InfoError(urlbase, 'CScardapio', 'teste checkout.jsx Erro: ' + error.response.status + ', ' + error.response.data);
            console.log('infoerror ', res);
        }
    }

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setDadosPessoais({ ...dadosPessoais, [id]: value });
    };

    const handleToggle = () => {
        setSelectedOption(selectedOption === 'entrega' ? 'retirada' : 'entrega');
    };

    const buscarCEP = async (cep) => {
        try {
            const response = await axios.get(`https://viacep.com.br/ws/${cep}/json`);

            if (response.data.erro) {
                showToast('CEP não encontrado', {
                    type: 'warning', theme: 'dark', position: 'top-center', hideProgressBar: true, autoClose: 2000
                });
            } else {
                setDadosPessoais({ ...dadosPessoais, endereco: response.data.logradouro, bairro: response.data.bairro, cidade: response.data.localidade, uf: response.data.uf });
                inputNumeroRef.current.focus();
                console.log('cep ', response);
            }
        } catch (error) {
            console.error("Erro ao obter cep:", error);

            showToast('Falha ao tentar obter o CEP', {
                type: 'error', theme: 'colored', position: 'top-center', hideProgressBar: true, autoClose: 3000
            });
        }
    }

    return (
        <>
            <Navbar showBack={true} titulo={'Finalizar Pedido'} />

            <ModalDialog
                tipo={1}
                titulo={'Deseja finalizar a venda ?'}
                subTitulo={''}
                confirmar={'Sim'}
                cancelar={'Não'}
                showDialog={showDialog}
                setShowDialog={setShowDialog}
                handleModalResponse={handleModalResponse.current}
            />

            <div className="container">
                {/* <div className="titulo text-center">
                    <h1>Finalizar Pedido</h1>
                </div> */}

                <div className="col-3">
                    {taxaEntrega > 0 &&
                        <div className="box-checkout">
                            <div className="input-group" style={{ marginBottom: 0 }}>
                                <div className="toggle-switch">
                                    <input type="checkbox" id="toggle" checked={selectedOption === "entrega"}
                                        className="toggle-input" onChange={handleToggle} disabled={finalizou} />
                                    <label htmlFor="toggle" className="toggle-label" style={finalizou ? { backgroundColor: '#ccc' } : {}}>
                                        <span className="toggle-span" style={finalizou ? { color: 'silver' } : {}}>
                                            {selectedOption === 'retirada' ? <FaPersonWalking style={{ fontSize: '1.2rem' }} /> : <FaMotorcycle style={{ fontSize: '1.2rem' }} />}
                                            {selectedOption === 'retirada' ? 'Retirada' : 'Entrega'}
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="box-checkout">
                        <h3>Dados Pessoais</h3>

                        <div className="input-group">
                            <p>Nome Completo</p>
                            <input type="text" id="nome" value={dadosPessoais.nome} onChange={handleInputChange} disabled={finalizou} ref={inputNomeRef} maxLength={60} />
                            {erros.nome && <span className="erro">{erros.nome}</span>}
                        </div>

                        <div className="input-group">
                            <p>E-mail</p>
                            <input type="email" id="email" value={dadosPessoais.email} onChange={handleInputChange} disabled={finalizou} maxLength={60} />
                        </div>

                        <div className="input-group" style={{ marginBottom: '0' }}>
                            <p>Telefone Contato</p>
                            <input type="text" id="fone" value={dadosPessoais.fone} onChange={handleInputChange} disabled={finalizou} ref={inputFoneRef} maxLength={30} />
                            {erros.fone && <span className="erro">{erros.fone}</span>}
                        </div>
                    </div>
                </div>

                {selectedOption === 'entrega' &&
                    <div className="col-3">
                        <div className="box-checkout">
                            <h3>Endereço Entrega</h3>

                            <div className="input-group">
                                <p>CEP</p>
                                <span className="busca-cep">
                                    <input type="text" id="cep" value={dadosPessoais.cep} onChange={handleInputChange} disabled={finalizou} maxLength={9} />
                                    <FaSistrix className="icone-pesquisa" style={pesquisaStyle} onClick={() => buscarCEP(dadosPessoais.cep)} />
                                </span>
                            </div>

                            <div className="input-group">
                                <p>Endereço</p>
                                <input type="text" id="endereco" value={dadosPessoais.endereco} onChange={handleInputChange} disabled={finalizou} maxLength={60} />
                                <p>Número</p>
                                <input type="text" id="numero" style={{ width: '50%' }} value={dadosPessoais.numero} onChange={handleInputChange} disabled={finalizou} ref={inputNumeroRef} maxLength={10} />
                            </div>

                            <div className="input-group">
                                <p>Bairro</p>
                                <input type="text" id="bairro" value={dadosPessoais.bairro} onChange={handleInputChange} disabled={finalizou} maxLength={40} />
                            </div>

                            <div className="input-group">
                                <p>Cidade</p>
                                <input type="text" id="cidade" value={dadosPessoais.cidade} onChange={handleInputChange} disabled={finalizou} maxLength={60} />
                            </div>

                            <div className="input-group" style={{ marginBottom: '0' }}>
                                <p>UF</p>
                                <select id="uf" className="select-uf" value={dadosPessoais.uf} onChange={handleInputChange} disabled={finalizou} >
                                    <option value="AC">Acre</option>
                                    <option value="AL">Alagoas</option>
                                    <option value="AP">Amapá</option>
                                    <option value="AM">Amazonas</option>
                                    <option value="BA">Bahia</option>
                                    <option value="CE">Ceará</option>
                                    <option value="DF">Distrito Federal</option>
                                    <option value="ES">Espírito Santo</option>
                                    <option value="GO">Goiás</option>
                                    <option value="MA">Maranhão</option>
                                    <option value="MT">Mato Grosso</option>
                                    <option value="MS">Mato Grosso do Sul</option>
                                    <option value="MG">Minas Gerais</option>
                                    <option value="PA">Pará</option>
                                    <option value="PB">Paraíba</option>
                                    <option value="PR">Paraná</option>
                                    <option value="PE">Pernambuco</option>
                                    <option value="PI">Piauí</option>
                                    <option value="RJ">Rio de Janeiro</option>
                                    <option value="RN">Rio Grande do Norte</option>
                                    <option value="RS">Rio Grande do Sul</option>
                                    <option value="RO">Rondônia</option>
                                    <option value="RR">Roraima</option>
                                    <option value="SC">Santa Catarina</option>
                                    <option value="SP">São Paulo</option>
                                    <option value="SE">Sergipe</option>
                                    <option value="TO">Tocantins</option>
                                    <option value="EX">Estrangeiro</option>
                                </select>
                            </div>
                        </div>
                    </div>
                }

                <div className="col-3">
                    <div className="box-checkout">
                        <div className="input-group" style={{ marginBottom: '0' }}>
                            <p className="observacao">Algum comentário?
                                <span>{dadosPessoais.obs.length} / 200</span>
                            </p>
                            <textarea style={{ height: '150px', resize: 'none' }} maxLength={200} placeholder="Ex: tirar a cebola, maionese a parte etc."
                                id="obs" value={dadosPessoais.obs} onChange={handleInputChange} disabled={finalizou} />
                        </div>
                    </div>

                    <div className="box-checkout">
                        <h3>Valores</h3>

                        <div className="input-group">
                            <div className="checkout-valores">
                                <span style={{ fontSize: '1rem' }}>Subtotal</span>
                                <span style={{ fontSize: '1rem' }}>
                                    <strong>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalCart)}</strong>
                                </span>
                            </div>
                        </div>

                        {taxaEntrega > 0 &&
                            <div className="input-group">
                                <div className="checkout-valores">
                                    <span style={{ fontSize: '1rem' }}>Taxa de entrega</span>
                                    <span style={{ fontSize: '1rem' }}>
                                        <strong>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(taxaEntrega)} {/* Taxa fixa pra testes */}</strong>
                                    </span>
                                </div>
                            </div>
                        }

                        <div className="checkout-valores">
                            <span>Total</span>
                            <span>
                                <strong>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalCart + taxaEntrega)}</strong>
                            </span>
                        </div>

                        <div className="checkout-button">
                            <button id="btn-checkout" className={`btn btn-checkout ${finalizou ? 'btn-disabled' : ''}`} onClick={finalizar} disabled={finalizou}>
                                Finalizar Pedido
                            </button>
                        </div>
                    </div>

                    <div style={{ height: '60px' }}></div>
                    <div style={{ height: '60px' }}></div>
                </div>
            </div>
        </>
    )
}

export default Checkout;