import React from 'react';
import "./footer.css";
import compsoft from "../../assets/compsoft.webp";
import { FaInstagram, FaFacebook } from "react-icons/fa6";

function Footer() {
    return (
        <div className="footer" >
            <div className='footer-topo'>
                <span>Conecte-se conosco nas redes sociais</span>
                <div className='redes'>
                    <a aria-label='Perfil no Instragram' href="https://www.instagram.com/compsoft.br/?hl=fr" target='_blank' rel='noopener noreferrer'>
                        <FaInstagram />
                    </a>

                    <a aria-label='Perfil no Facebook' href="https://www.facebook.com/compsoft.ar/?locale=pt_BR" target='_blank' rel='noopener noreferrer'>
                        <FaFacebook />
                    </a>
                </div>
            </div>

            <div className="footer-box">
                <div className="footer-container">
                    <div className="footer-row">
                        <div className="footer-column">
                            <div className="footer-heading descricao">Desenvolvido por:</div>

                            <a className="footer-logo" aria-label='Nosso portal' href="http://www.compsoft.com.br/portalcompsoft/" target='_blank' rel='noopener noreferrer'>
                                <img src={compsoft} alt="Logo" style={{ maxWidth: '60%' }} loading='lazy' />
                            </a>

                            <div className="footer-descricao">
                                Empresa de desenvolvimento de sistemas, soluções em automação comercial e fornecimentos de equipamentos.
                            </div>
                        </div>

                        <div className="footer-column">
                            <div className="footer-heading">Contato</div>

                            <div className="footer-text">
                                Av. Washington Luiz, 250 Sala 310
                            </div>
                            <div className="footer-text">
                                Araras, SP 13600-720 Brasil
                            </div>
                            <div className="footer-text">
                                19-3551 1163
                            </div>
                            <div className="footer-link">
                                <a href="mailto:contato@compsoft.com.br">contato@compsoft.com.br</a>
                            </div>
                        </div>

                        {/*<div className="footer-column">
                            <div className="footer-heading">Contate-nos</div>
                            <div className="footer-link" href="#">
                                Uttar Pradesh
                            </div>
                            <div className="footer-link" href="#">
                                Ahemdabad
                            </div>
                            <div className="footer-link" href="#">
                                Indore
                            </div>
                            <div className="footer-link" href="#">
                                Mumbai
                            </div>
                        </div> */}

                        {/* <div className="footer-column">
                            <div className="footer-heading">Social</div>
                            <div className="footer-link" href="#">
                                <i className="fab fa-facebook-f">
                                    <span
                                        style={{
                                            marginLeft: "10px",
                                        }}
                                    >
                                        Facebook
                                    </span>
                                </i>
                            </div>
                            <div className="footer-link" href="#">
                                <i className="fab fa-instagram">
                                    <span
                                        style={{
                                            marginLeft: "10px",
                                        }}
                                    >
                                        Instagram
                                    </span>
                                </i>
                            </div>
                            <div className="footer-link" href="#">
                                <i className="fab fa-twitter">
                                    <span
                                        style={{
                                            marginLeft: "10px",
                                        }}
                                    >
                                        Twitter
                                    </span>
                                </i>
                            </div>
                            <div className="footer-link" href="#">
                                <i className="fab fa-youtube">
                                    <span
                                        style={{
                                            marginLeft: "10px",
                                        }}
                                    >
                                        Youtube
                                    </span>
                                </i>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
