import "./barra-filtro.css";
import React, { useRef } from 'react';
import { FaArrowDownWideShort, FaBrazilianRealSign, FaCircleXmark, FaList } from "react-icons/fa6";
import { useAppContext } from "../../contexts/AppContext";
import { capitalizeFirstLetter, useDragScroll } from "../../funcoes";

function BarraFiltro({ filtrou, dadosFiltro, setDadosFiltro }) {

    // const [filtro, setFiltro] = useState({ ordenacao: 'C', precoMinimo: '', precoMaximo: '' });
    // const { dadosFiltro } = useHomeContext();
    const { botaodestaquecor, botaodestaquefonte, categoria } = useAppContext();
    const divRef = useRef(null);
    const { handleMouseDown, handleMouseMove, handleTouchStart, handleTouchMove, isDragging, isDelayedClick } = useDragScroll();

    const filtroStyle = {
        backgroundColor: botaodestaquecor,
        color: botaodestaquefonte
    }

    const limparCategoria = (findCategoria) => {
        if ((!isDelayedClick) && (!isDragging)) {
            const novoCategorias = dadosFiltro.categoriasSelecionadas.filter(controle => controle !== findCategoria.controle);
            setDadosFiltro({ ...dadosFiltro, categoriasSelecionadas: novoCategorias });
        }
    }

    const limparOrdenacao = () => {
        if ((!isDelayedClick) && (!isDragging)) {
            setDadosFiltro({ ...dadosFiltro, ordenacao: '' });
        }
    }

    const limparPreco = () => {
        if ((!isDelayedClick) && (!isDragging)) {
            setDadosFiltro({ ...dadosFiltro, precoMinimo: '', precoMaximo: '' });
        }
    }

    return (
        <>
            {filtrou &&
                <div className="barra-filtro"
                    ref={divRef}
                    onMouseDown={(e) => handleMouseDown(e, divRef)}
                    onMouseMove={(e) => handleMouseMove(e, divRef)}
                    onTouchStart={(e) => handleTouchStart(e, divRef)}
                    onTouchMove={(e) => handleTouchMove(e, divRef)}
                >

                    {dadosFiltro.ordenacao !== '' &&
                        <div className="filtro-bloco" style={filtroStyle}>
                            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                <FaArrowDownWideShort style={{ marginRight: '5px' }} />

                                {dadosFiltro.ordenacao === 'C' &&
                                    <span>Crescente (A-Z)</span>
                                }
                                {dadosFiltro.ordenacao === 'D' &&
                                    <span>Decrescente (Z-A)</span>
                                }
                                {dadosFiltro.ordenacao === 'MA' &&
                                    <span>Maior Preço</span>
                                }
                                {dadosFiltro.ordenacao === 'ME' &&
                                    <span>Menor Preço</span>
                                }
                                {dadosFiltro.ordenacao === 'MAV' &&
                                    <span>Mais Vendidos</span>
                                }
                                {dadosFiltro.ordenacao === 'MEV' &&
                                    <span>Menos Vendidos</span>
                                }

                                <FaCircleXmark className="icone-fechar-barra" onClick={limparOrdenacao} />
                            </div>
                        </div>
                    }


                    {((dadosFiltro.precoMinimo > 0) || (dadosFiltro.precoMaximo > 0)) &&
                        <div className="filtro-bloco" style={filtroStyle}>
                            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                <span>
                                    <FaBrazilianRealSign style={{ marginRight: '5px' }} />

                                    <div style={{ display: 'inline-flex' }}>
                                        <span style={{ marginRight: '3px' }}>
                                            {new Intl.NumberFormat('pt-BR', { style: 'decimal', minimumFractionDigits: 2 }).format(dadosFiltro.precoMinimo)}
                                        </span>
                                        <span>-</span>
                                        <span style={{ marginLeft: '3px' }}>
                                            {new Intl.NumberFormat('pt-BR', { style: 'decimal', minimumFractionDigits: 2 }).format(dadosFiltro.precoMaximo)}
                                        </span>
                                    </div>

                                    <FaCircleXmark className="icone-fechar-barra" onClick={limparPreco} />
                                </span>
                            </div>
                        </div>
                    }

                    {dadosFiltro.categoriasSelecionadas.length > 0 && categoria &&
                        dadosFiltro.categoriasSelecionadas.map(function (controle, index) {
                            const findCategoria = categoria.find(categoria => categoria.controle === controle);
                            if (findCategoria) {
                                return (
                                    <div key={index} className="filtro-bloco" style={filtroStyle}>
                                        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                            <FaList style={{ marginRight: '5px' }} />
                                            <span>{capitalizeFirstLetter(findCategoria.nome)}</span>
                                            <FaCircleXmark className="icone-fechar-barra" onClick={() => limparCategoria(findCategoria)} />
                                        </div>
                                    </div>
                                )
                            }
                            return null;
                        })
                    }
                </div>
            }
        </>
    );
}

export default BarraFiltro;