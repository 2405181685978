// AppContext.js

import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useToast } from './toastContext.js';
import { getAuth, getUrlApi } from '../funcoes.js';

// AppContext
const AppContext = createContext();

export const AppProvider = ({ children, cnpjParam }) => {
  const [urlbase, setUrlbase] = useState('');
  const [apptitulo, setAppTitulo] = useState('Aguarde...');
  const [appmensagem, setAppMensagem] = useState('Carregando nosso menu...');
  const [corfontedestaque, setCorFonteDestaque] = useState('');
  const [corfontenormal, setCorFonteNormal] = useState('');
  const [corfundo, setCorFundo] = useState('');
  const [botaonormalcor, setBotaoNormalCor] = useState('');
  const [botaonormalfonte, setBotaoNormalFonte] = useState('');
  const [botaodestaquecor, setBotaoDestaqueCor] = useState('');
  const [botaodestaquefonte, setBotaoDestaqueFonte] = useState('');
  const [produtos, setProdutos] = useState([]);
  const [produtosCopia, setProdutosCopia] = useState([]);
  const { showToast, updateToast } = useToast();
  const [carregarProdutos, setCarregarProdutos] = useState(true);
  const [categoria, setCategoria] = useState([]);

  const fetchData = useCallback(async (cnpj) => {
    const auth = getAuth();

    try {
      const response = await axios.get(`${getUrlApi(false)}/restaurante/configura/${cnpj}`, {
        auth: auth
      });

      console.log('appcontext ', response);

      // Extrair dados da resposta e atualizar o estado
      const { titulo, mensagem, corfontedestaque, corfontenormal, corfundo, botaonormalcor, botaonormalfonte, botaodestaquecor, botaodestaquefonte } = response.data[0];
      setAppTitulo(titulo);
      setAppMensagem(mensagem);
      setCorFonteDestaque(corfontedestaque);
      setCorFonteNormal(corfontenormal);
      setCorFundo(corfundo);
      setBotaoNormalCor(botaonormalcor);
      setBotaoNormalFonte(botaonormalfonte);
      setBotaoDestaqueCor(botaodestaquecor);
      setBotaoDestaqueFonte(botaodestaquefonte);
      setUrlbase(cnpj);
    } catch (error) {
      console.error('Erro ao buscar dados da API:', error);

      showToast('Falha ao obter Dados', {
        type: 'error', theme: 'colored', position: 'top-center', hideProgressBar: true, autoClose: 5000
      });
    }
  }, [setAppTitulo, setAppMensagem, setCorFonteDestaque, setCorFonteNormal, setCorFundo,
    setBotaoNormalCor, setBotaoNormalFonte, setBotaoDestaqueCor, setBotaoDestaqueFonte, setUrlbase, showToast]
  );

  useEffect(() => {
    const auth = getAuth();
    const authConfig = {
      auth: auth
    };

    const fetchProdutos = async () => {
      showToast('Carregando produtos', {
        type: 'loading', theme: 'dark', position: 'top-center', isLoading: true, hideProgressBar: true
      });

      try {
        const responseProd = await axios.get(`${getUrlApi(false)}/produtos/${urlbase}`, authConfig);
        const listaProduto = await responseProd.data.map(produto => ({ ...produto, categorias: [] }));

        console.log('lista produtos ', responseProd);
        updateToast('Carregando categorias');

        const responseCat = await axios.get(`${getUrlApi(false)}/categoriasproduto/${urlbase}`, authConfig);
        console.log('lista categoriasproduto ', responseCat.data);

        const produtosComCategorias = await listaProduto.map(produto => {
          const categoriasDoProduto = responseCat.data.filter(categoria => categoria.produto === produto.controle);

          const urlImagem = `https://compsoft.com.br/cscardapio/imagens/${urlbase}/${produto.controle}.png`;
          // const image = new Image();
          // image.src = urlImagem;

          produto.foto = urlImagem;
          produto.categorias = categoriasDoProduto;

          return produto;
        });

        console.log('lista produtos pronta ', produtosComCategorias);
        setProdutos(produtosComCategorias);
        setProdutosCopia(produtosComCategorias);
        setCarregarProdutos(false);

        setTimeout(() => {
          updateToast('Pronto', { type: "success", isLoading: false, autoClose: 1000, hideProgressBar: true });
        }, 100);
      } catch (error) {
        console.error("Erro ao obter produtos:", error);

        updateToast('Falha ao obter produtos', {
          type: 'error', isLoading: false, theme: 'colored', position: 'top-center', hideProgressBar: true, autoClose: 5000
        });

        return [];
      }
    }

    const fetchCategorias = async () => {
      try {
        const response = await axios.get(`${getUrlApi(false)}/categorias/${urlbase}`, authConfig);

        console.log('lista categorias ', response.data);
        const listaCategoria = [{ controle: 0, nome: 'Todos' }, ...response.data]
        setCategoria(listaCategoria);
      } catch (error) {
        console.error("Erro ao obter categorias:", error);

        showToast('Falha ao obter as Categorias', {
          type: 'error', theme: 'colored', position: 'top-center', hideProgressBar: true, autoClose: 5000
        });

        return [];
      }
    }

    fetchData(cnpjParam); // Chama a função fetchData com o parâmetro dinâmico

    if ((urlbase) && (carregarProdutos)) {
      fetchProdutos();
      fetchCategorias();
    }
  }, [urlbase, cnpjParam, fetchData, showToast, updateToast, carregarProdutos]);

  return (
    <AppContext.Provider value={{
      urlbase,
      apptitulo,
      appmensagem,
      corfontedestaque,
      corfontenormal,
      corfundo,
      botaonormalcor,
      botaonormalfonte,
      botaodestaquecor,
      botaodestaquefonte,
      produtos,
      produtosCopia,
      categoria,
      setUrlbase,
      setAppTitulo,
      setAppMensagem,
      setCorFonteDestaque,
      setCorFonteNormal,
      setCorFundo,
      setBotaoNormalCor,
      setBotaoNormalFonte,
      setBotaoDestaqueCor,
      setBotaoDestaqueFonte,
      setProdutos,
      setCarregarProdutos,
      fetchData // Adiciona a função fetchData ao contexto
    }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error('useAppContext deve ser usado dentro de um AppProvider');
  }

  return context;
};
