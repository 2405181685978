import { useEffect, useState, useContext, useCallback, useRef } from "react";
import { Dock } from "react-dock";
import ProdutoCart from "../produto-cart/produto-cart.jsx";
import "./cart.css";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../../contexts/cart-context.jsx";
import { FaXmark, FaBagShopping } from "react-icons/fa6";
import { useToast } from "../../contexts/toastContext.js";
import { convertHexToRgba, useDockWidth } from "../../funcoes.js";
import ModalDialog from "../modal-dialog/modal-dialog.jsx";
import { useAppContext } from "../../contexts/AppContext.js";

function Cart() {

    const [show, setShow] = useState(false);
    const { cartItems, totalCart, LimpaItensCart } = useContext(CartContext);
    const { botaodestaquecor } = useAppContext();
    const { showToast } = useToast();
    const dockSize = useDockWidth();
    const navigate = useNavigate();
    const [showDialog, setShowDialog] = useState(false);
    const handleModalResponse = useRef(null);
    const backgroundSacola = convertHexToRgba(botaodestaquecor, 0.1);

    const html = document.querySelector('html');
    const scrollToTop = document.querySelector('#scroll-to-top');

    const iconeSacolaStyle = {
        color: botaodestaquecor || 'silver'
    };

    const hideDock = useCallback(() => {
        html.style.overflow = 'auto';

        if (scrollToTop)
            scrollToTop.style.display = '';

        setShow(false);
    }, [html, scrollToTop]);

    useEffect(function () {
        if (show) {
            html.style.overflow = 'hidden';

            if (scrollToTop)
                scrollToTop.style.display = 'none';
        } else {
            html.style.overflow = 'auto';

            if (scrollToTop)
                scrollToTop.style.display = '';
        }
    }, [show, scrollToTop, html]);

    useEffect(function () {
        window.addEventListener('openSidebar', function () {
            setShow(true);
        });

        if (cartItems.length === 0)
            hideDock();

        return () => {
            window.removeEventListener('openSidebar', null);
        }
    }, [cartItems, hideDock]);

    const checkout = () => {
        html.style.overflow = 'auto';

        if (scrollToTop)
            scrollToTop.style.display = '';

        navigate('/checkout');
    }

    const abrirDialog = () => {
        setShowDialog(true); // Abre o dialog;
        return new Promise((resolve, reject) => {
            return handleModalResponse.current = resolve;
        });
    };

    const esvaziarSacola = async () => {
        const response = await abrirDialog();
        if (response === 'cancelado') {
            return;
        }

        LimpaItensCart();
        showToast('A sacola foi esvaziada', {
            type: 'info', theme: 'dark', position: 'bottom-center', hideProgressBar: true, autoClose: 2000
        });
    }

    return (
        <>
            <ModalDialog
                tipo={1}
                titulo={'Deseja realmente esvaziar a sacola ?'}
                subTitulo={''}
                confirmar={'Sim'}
                cancelar={'Não'}
                showDialog={showDialog}
                setShowDialog={setShowDialog}
                handleModalResponse={handleModalResponse.current}
                hideScrollAfterClose={true}
            />

            <Dock position="right"
                isVisible={show}
                fluid={false}
                size={dockSize}
                onVisibleChange={function (visible) {
                    html.style.overflow = 'auto';

                    if (scrollToTop)
                        scrollToTop.style.display = '';

                    setShow(visible);
                }} dockStyle={{ borderRadius: '10px' }}>

                <div className={`fundo-cart ${show ? 'dock-open' : ''}`}>
                    <div className="text-center meu-pedido">
                        <h1 className="meu-pedido-h1">Meu Pedido</h1>
                        <FaXmark className="btn-fechar" onClick={hideDock} />
                    </div>

                    <div className="lista-produtos-cart">
                        {cartItems.length === 0 &&
                            <div className="sacola-vazia">
                                <div className="sacola-vazia-icone-wrapper" style={{ '--sacola-background': backgroundSacola }}>
                                    <FaBagShopping className="sacola-vazia-icone" style={iconeSacolaStyle} />
                                </div>

                                <div className="sacola-vazia-texto">
                                    <span>Sua Sacola Está Vazia</span>
                                </div>
                            </div>
                        }

                        {
                            cartItems.map(function (item) {
                                const randomNumber = Math.floor(Math.random() * 1000000);

                                return <ProdutoCart
                                    // key={item.id}
                                    key={randomNumber}
                                    id={item.id}
                                    controle={item.controle}
                                    foto={item.foto}
                                    nome={item.nome}
                                    qtd={item.qtd}
                                    valor={item.valor}
                                    composicao={item.composicao}
                                    adicionais={item.adicionais}
                                    adicionaisComposicao={item.adicionaisComposicao}
                                    valoradicional={item.valoradicional}
                                    imagemCarregada={item.imagemCarregada} />
                            })
                        }

                        {cartItems.length > 0 &&
                            <div style={{ height: '60px' }}></div>
                        }
                    </div>

                    <div className="footer-cart">
                        <div className="footer-cart-valor">
                            <span>Total</span>
                            <span>
                                <strong>
                                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalCart)}
                                </strong>
                            </span>
                        </div>
                        <div className="checkout">
                            <button onClick={checkout} className={`btn btn-checkout ${cartItems.length <= 0 ? 'btn-disabled' : ''}`} disabled={cartItems.length <= 0}>
                                Finalizar Pedido
                            </button>
                        </div>

                        <div className="checkout" style={{ textAlign: 'center' }}>
                            <div className="btn-esvaziar-sacola">
                                <span onClick={esvaziarSacola} className={`text-red esvaziar ${cartItems.length <= 0 ? 'link-disabled' : ''}`} disabled={cartItems.length <= 0}>
                                    Esvaziar sacola
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </Dock >
        </>
    )
}

export default Cart;